import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData, postStbFormData } from "./api/Api"
import { phoneNumberValidation, Mail_Function, raptorFormSchema } from "../common/utils"

import axios from "axios"
import * as qs from "query-string"

function GetFreeQuoter(props) {
  const [startDate, setStartDate] = useState();

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: props.formName,
        form_type: props.formType,
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "get_free_quote_user",
        email_temp_admin: "get_free_quote_admin",
        email_server_func: "get_free_quote",
        event_tracking: "get_free_quote",
        page_url: '/services/landlords/property-management-qatar',
        email_subject_user: "Get a Quote",
        email_subject_admin: "Property Management Service Quote"
      },
      {
        grpmd: "12",
        label: "Property Type",
        placeholder: "Property Type",
        name: "property_type",
        element: "select",
        required: true,
        values: ['Apartment', 'Townhouse', 'Villa', 'Penthouse', 'Duplex', 'Land', 'Office Space'],
        labelClass: "annual-txt",
        class: "full_wdth_box popup_select"
      }, 
      {
        grpmd: "12",
        label: "Bedrooms",
        placeholder: "Bedrooms",
        name: "bedrooms",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[0-9]+$",
        labelClass: "content_b-18"
      }, 
      {
        grpmd: "12",
        label: "Property Size (Sqm)",
        placeholder: "Property Size",
        name: "property_size",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18"
      },   
      {
        grpmd: "12",
        label: "Name",
        placeholder: "Your Name",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "Email",
        placeholder: "Your Email Address",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "content_b-18"

      },
      {
        grpmd: "12",
        label: "Telephone",
        placeholder: "+971 12345678",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk: ".{5,20}",
        labelClass: "content_b-18"

      },   

      
      {
        grpmd: "12",
        label: "Message",
        placeholder: "Write your message here",
        name: "message",
        element: "textarea",
        class: "mb-4",
        rows:"2"
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit",
        class: "btn-primary col-md-12",
        labelClass: "",
        customClass:"btn-wrapper col-md-12 col-sm-12 col-xs-12"
      },
      {
        text: 'By clicking Submit, I agree to The Pearl Gates <a href="/terms-and-conditions" className="content-link">Terms & Conditions</a>',
        element: "html",
        class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
      {
        name: "negotiatoremail",
        type: "hidden",
        element: "input",
        defaultValue: props.subject?.negotiator?.Email
      }
    ]);

    const handlechange = event => {
        // remove initial empty spaces
        //console.log("select_event",event);
        if(event.type === "select"){
          event.value = event.value.trimStart()
        } else{
          if(event.target.name === "telephone"){
            var valid_phone = phoneNumberValidation(event.target.value);
            if(valid_phone){
              event.target.value = valid_phone;
            } else{
              event.target.value = '';
            }
          } else{
            event.target.value = event.target.value.trimStart()
          }
        }
    
    }

  useEffect(() => {
    if (token !== '') {

 const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {

        formvalues['g_recaptcha_response'] = token;
        await Mail_Function(formvalues);
        window.grecaptcha.reset();

      });
      postStbFormData(raptorFormSchema(formvalues)).then(async apiRes => {  
        console.log('raptor submission', apiRes)
       });


      // const axiosOptions_netlify = {
      //   url: fields[0].page_url,
      //   method: "post",
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //   data: qs.stringify(formvalues),
      // }

      // axios(axiosOptions_netlify)
      //   .then(response => {
      //     console.log('data stored')
      //   })
      //   .catch(err =>
      //     console.log(err)
      //   );
      // const url = typeof window !== 'undefined' ? window.location.href : ''  
      // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
      //   fields[0].formname = 'Get mortgage help';
      // }

      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g_recaptcha_response'] = token;

      json['extra'] = {"property_type": json.property_type, "bedrooms": json.bedrooms, "property_size": json.property_size};
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate()
    }
  };

  //console.log("property_data", props.formLabel, props.location.pathname)

  return (
    <div className="form stbform">
      <div ref={myRef} />
    <div className="form stbform">
      
    {showerror && <div className="alert alert-error">  
      {fields[0].error_text}
    </div>}

    {showthankyou && <div className="alert alert-success">
      {fields[0].success_text}
    </div>}

    
    
      <Form className="contact-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} >
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />

        <input type="hidden" name="bot-field" />

        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  label={field.label}
                  startDate={field.type === 'date_time' ? startDate : ''}
                  setStartDate={field.type === 'date_time' ? setStartDate : ''}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  phoneNumber={field.name === 'telephone' ? phoneNumber : ''}
                  setPhoneNumber={field.name === 'telephone' ? setPhoneNumber : ''}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  label={field.label}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  label={field.label}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  label={field.label}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  label={field.label}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  lastchild={field.lastchild}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <>
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
                
                </>
              );
            }
          })
        }
        </Form.Row>
      </Form>

    </div>
    </div>
  );
}


const GetFreeQuoterForm = (props) => (
  <GetFreeQuoter {...props}  />
)

export default GetFreeQuoterForm